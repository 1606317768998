:root {
  --primary-color: #fff;
  --primary-color2: #1f1f2c;
  /* --primary-color3: #5142fc; */
  --primary-color3: #ea7a99;
  --primary-color4: #7a798a;
  --primary-color5: #f8f8f8;
  --primary-color6: #14141f;
  --primary-color7: #f8f8f8;
  --bg-section2: #f8f8f8;
  --primary-color8: #7a798a;
  --primary-color9: #7a798a;

  --default-light-banner-url: #ea7a99;
  --profile-light-banner-url: #ea7a99;

  /* --default-light-banner-url: url(../images/default-light-banner.webp);
  --profile-light-banner-url: url(../images/profile-light-banner.webp); */
}

.is_dark {
  --primary-color: #343444;
  --primary-color2: #fff;
  --primary-color3: #5142fc;
  --primary-color4: rgba(255, 255, 255, 0.9);
  --primary-color5: #030303;
  --primary-color6: #fff;
  --primary-color7: #343444;
  --bg-section2: #14141f;
  --primary-color8: #fff;
  --primary-color9: #8a8aa0;

  --default-banner-url: url(../images/default-banner.webp);
  --profile-banner-url: url(../images/profile-banner.webp);
}
